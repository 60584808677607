import styled, { css } from 'styled-components'
import BgImg from '@images/purple-bg2.jpg'

export const Wrapper = styled.section`
  overflow: hidden;
  position: relative;
  margin: 0;
  margin: 80px 0;
  padding-top: 60px;
  padding-bottom: 60px;
  transition: background-color 1s 1s ease;
  color: ${({ theme }) => theme.colors.white};

  &::before {
    content: '';
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background: center center url(${BgImg}) no-repeat;
    background-size: cover;
    filter: blur(${({ active }) => (active ? `3px` : `0`)});
    transition: filter 1s 1s ease;
  }

  p {
    font-size: 30px;
    font-weight: 900;
    font-weight: 900;
    line-height: 1.2;

    > span {
      overflow: hidden;
      position: relative;
      display: inline-block;

      > span {
        position: relative;
        display: inline-block;
        transform: translate3d(
          0,
          ${({ active }) => (active ? '0' : '100%')},
          0
        );
        transition: transform 0.8s ease;
      }

      + span {
        padding-left: 5px;
      }

      &:nth-child(2) > span {
        transition-delay: ${({ delay }) => delay + 0.2}s;
      }

      &:nth-child(3) > span {
        transition-delay: ${({ delay }) => delay + 0.4}s;
      }

      &:nth-child(4) > span {
        transition-delay: ${({ delay }) => delay + 0.6}s;
      }

      &:nth-child(5) > span {
        transition-delay: ${({ delay }) => delay + 0.8}s;
      }

      &:nth-child(6) > span {
        transition-delay: ${({ delay }) => delay + 1}s;
      }

      &:nth-child(7) > span {
        transition-delay: ${({ delay }) => delay + 1.2}s;
      }

      &:nth-child(8) > span {
        transition-delay: ${({ delay }) => delay + 1.4}s;
      }

      &:nth-child(9) > span {
        transition-delay: ${({ delay }) => delay + 1.6}s;
      }

      &:nth-child(10) > span {
        transition-delay: ${({ delay }) => delay + 1.8}s;
      }
    }
  }

  ${({ theme }) => theme.media.s} {
    p {
      font-size: 40px;
    }
  }
  ${({ theme }) => theme.media.m} {
    padding-top: 140px;
    padding-bottom: 140px;

    p {
      font-size: 60px;
    }
  }
  ${({ theme }) => theme.media.l} {
    p {
      font-size: 80px;
    }
  }
  ${({ theme }) => theme.media.xl} {
    padding-top: 160px;
    padding-bottom: 160px;

    p {
      font-size: 90px;
    }
  }
  ${({ theme }) => theme.media.xxl} {
    p {
      font-size: 100px;
    }
  }
`
