import React from 'react'
import PropTypes from 'prop-types'

import HorWrap from '@atoms/HorWrap'

import { Wrapper } from './styles.js'

const UsPurple = ({ active, data }) => {
  return (
    <Wrapper active={active} delay={0}>
      <HorWrap>
        <p>
          {data.map(service => (
            <span key={service.id}>
              <span>{service.name}. </span>
            </span>
          ))}
        </p>
      </HorWrap>
    </Wrapper>
  )
}

UsPurple.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

UsPurple.defaultProps = {
  children: null,
}

export default UsPurple
