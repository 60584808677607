import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  padding: 0;
  opacity: 1;

  .react-parallax {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .react-parallax-background {
    height: 160%;
  }

  .react-parallax-background-children {
    width: 100vw;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    opacity: ${({ active }) => (active ? `1` : `0`)};
    filter: blur(${({ active }) => (active ? `0` : `10px`)});
    transition: filter 1s 3s ease, opacity 1s 2.2s ease;
  }

  ${({ theme }) => theme.media.s} {
    .react-parallax {
      // height: 700px;
    }
  }

  ${({ theme }) => theme.media.m} {
    .react-parallax {
      // height: 750px;
    }

    video {
      width: 100%;
      height: auto;
    }
  }

  ${({ theme }) => theme.media.l} {
    .react-parallax {
      height: 800px;
    }
  }

  ${({ theme }) => theme.media.xl} {
    .react-parallax {
      height: 850px;
    }
  }

  ${({ theme }) => theme.media.xxl} {
    .react-parallax {
      height: 900px;
    }
  }
`

export const Content = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  color: ${({ theme }) => theme.colors.white};

  h3 {
    overflow: hidden;
    position: relative;
    width: 300px;
    height: 70px;
    font-size: 50px;
    font-weight: 900;
    line-height: 50px;

    > span {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      animation: slideTextUp 16s ease;
      animation-iteration-count: infinite;
      transform: translate3d(0, -100%, 0);
      text-align: center;

      &:nth-child(1) {
        animation-delay: 12s;
      }

      &:nth-child(2) {
        animation-delay: 0s;
      }

      &:nth-child(3) {
        animation-delay: 4s;
      }

      &:nth-child(4) {
        animation-delay: 8s;
      }
    }
  }

  p {
    display: none;
  }

  ${({ theme }) => theme.media.s} {
    h3 {
      width: 420px;
      height: 90px;
      font-size: 70px;
    }
  }

  ${({ theme }) => theme.media.m} {
    h3 {
      width: 600px;
      height: 135px;
      font-size: 115px;
    }

    p {
      display: block;
      max-width: 500px;
    }
  }

  ${({ theme }) => theme.media.l} {
    h3 {
      width: 900px;
      height: 140px;
      font-size: 120px;
    }

    p {
      max-width: 550px;
    }
  }

  ${({ theme }) => theme.media.xl} {
    h3 {
      width: 1100px;
      height: 150px;
      font-size: 130px;
    }

    p {
      max-width: 570px;
    }
  }

  ${({ theme }) => theme.media.xxl} {
    h3 {
      height: 160px;
      font-size: 140px;
    }

    p {
      max-width: 600px;
    }
  }

  @keyframes slideTextUp {
    0% {
      transform: translate3d(0, 100%, 0);
    }
    6.25% {
      transform: translate3d(0, 0, 0);
    }
    6.25% {
      transform: translate3d(0, 0, 0);
    }
    25% {
      transform: translate3d(0, 0, 0);
    }
    31.25% {
      transform: translate3d(0, -100%, 0);
    }
    31.25% {
      transform: translate3d(0, -100%, 0);
    }
    100% {
      transform: translate3d(0, -100%, 0);
    }
  }
`

export const VideoCurtain = styled.div`
  z-index 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: ${({ active }) => (active ? '100%' : '0')};
    height: 100%;
    transform: translate3d(-50%, 0, 0);
    transition: width 0.8s 1s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 50%;
      background: white;
      transition: transform 0.8s 2.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::before {
      bottom: 50%;
      border-bottom: 2px solid black;
      transform: translate3d(0, ${({ active }) => (active ? '-100%' : '0')}, 0);
    }

    &::after {
      top: calc(50% - 3px);
      border-top: 2px solid black;
      transform: translate3d(0, ${({ active }) => (active ? '100%' : '0')}, 0);
    }
  }
`
