import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  margin: 0;
  margin: 60px 0;
  padding: 0;
  transition: background-color 1s 1s ease;
  ${({ active }) =>
    active
      ? css`
          background-color: white;
        `
      : css`
          background-color: transparent;
        `}

  > div {
    ${({ active }) =>
      active
        ? css`
            filter: blur(0);
            opacity: 1;
          `
        : css`
            filter: blur(5px);
            opacity: 0;
          `}
  }

  ${({ theme }) => theme.media.l} {
  }
`

export const Content = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 5px;
  row-gap: 5px;

  > li {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    aspect-ratio: 1 / 1.25;
    padding: 30px;
    color: white;

    h3 {
      margin: 0 0 10px;
      font-size: 22px;
      font-weight: 900;
    }

    p {
      margin: 0 0 10px;
      font-size: 14px;
    }
  }

  ${({ theme }) => theme.media.m} {
    grid-template-columns: repeat(3, 1fr);
    margin-right: -${({ theme }) => theme.paddings.m};

    > li {
      padding: 30px;

      h3 {
        font-size: 26px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  ${({ theme }) => theme.media.l} {
    margin-right: -${({ theme }) => theme.paddings.l};

    > li {
      padding: 40px;
      aspect-ratio: 1 / 1.52;
    }
  }

  ${({ theme }) => theme.media.xl} {
    margin-right: -${({ theme }) => theme.paddings.xl};

    > li {
      h3 {
        font-size: 30px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  ${({ theme }) => theme.media.xxl} {
    margin-right: -${({ theme }) => theme.paddings.xxl};
  }
`

export const Person = styled.li`
  background: center center url(${({ BgImg }) => BgImg}) no-repeat;
  background-size: cover;
`
