import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { Wrapper, Content } from './styles.js'

const TilesSmall = ({ active, tiles }) => {
  return (
    <Wrapper>
      <Content active={active}>
        {tiles.map(tile => (
          <li key={tile.id}>
            <SVG src={tile.logo.url} />
          </li>
        ))}
      </Content>
    </Wrapper>
  )
}

TilesSmall.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.object),
}

TilesSmall.defaultProps = {
  tiles: [],
}

export default TilesSmall
