import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles.js'

const TextSection = ({ active, themeStyle, children }) => {
  return (
    <Wrapper active={active} themeStyle={themeStyle}>
      {children}
    </Wrapper>
  )
}

TextSection.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType,
}

TextSection.defaultProps = {
  active: false,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

export default TextSection
