import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Main from '@templates/Main'
import { useInView } from 'react-intersection-observer'

import AsideNav from '@atoms/AsideNav'
import HorWrap from '@atoms/HorWrap'
import OrangeHeader from '@atoms/OrangeHeader'
import PurpleHeader from '@atoms/PurpleHeader'

import HeroSection from '@organisms/HeroSection'
import OnlyText from '@organisms/OnlyText'
import UsTeam from '@organisms/UsTeam'
import TextSection from '@organisms/TextSection'
import UsPurple from '@organisms/UsPurple'
import TilesBig from '@organisms/TilesBig'
import TilesSmall from '@organisms/TilesSmall'
import VideoFull from '@organisms/VideoFull'
import Newsletter from '@organisms/Newsletter'

import CreativeVideo from '@images/videos/creative.mp4'
import HeroVideo from '@images/videos/hero-us.mp4'
import ReactMarkdown from 'react-markdown'

const UsPage = ({ data, transitionStatus }) => {
  const pageData = data.datoCmsUsPage
  const [activeSection, setActiveSection] = useState('#sec-one')
  const [refSec2, Sec2InView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })
  const [refSec3, Sec3InView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })
  const [refSec35, Sec35InView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })
  const [refSec4, Sec4InView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })
  const [refSec5, Sec5InView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })
  const [refSec6, Sec6InView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })

  const [startPage, setStartPage] = useState(false)

  useEffect(() => {
    setTimeout(() => setStartPage(true), 100)
  }, [setStartPage])

  const navigation = [
    {
      id: '001',
      name: 'Hello',
      url: '#sec-one',
    },
    {
      id: '002',
      name: 'You. We. Us.',
      url: '#sec-two',
    },
    {
      id: '003',
      name: 'Team',
      url: '#sec-three',
    },
    {
      id: '004',
      name: 'Our services',
      url: '#sec-four',
    },
    {
      id: '005',
      name: 'Clients',
      url: '#sec-five',
    },
    {
      id: '005',
      name: 'Awards',
      url: '#sec-six',
    },
  ]

  return (
    <Main
      seo={pageData.seoMetaTags}
      transitionStatus={transitionStatus}
      enterColor="#4100F5"
    >
      <div id="sec-one">
        <HeroSection
          active={startPage}
          video="774001854"
          mobileVideo="801056912"
          path="us"
          data={{
            header: pageData.heroHeader,
            line1: pageData.heroLine1,
            line2: pageData.heroLine2,
            button: pageData.heroButton[0].text,
            buttonLink: `/${pageData.heroButton[0].internalLink.slug}`,
          }}
        />
      </div>
      <div ref={refSec2} id="sec-two">
        <HorWrap>
          <OnlyText
            orangeHeader="02 What we do"
            header={
              <PurpleHeader
                active={Sec2InView && transitionStatus === 'entered'}
                delay={2}
                text={pageData.wwdHeader.split(' ')}
              />
            }
            active={Sec2InView && transitionStatus === 'entered'}
          >
            <ReactMarkdown children={pageData.wwdDescription} />
          </OnlyText>
        </HorWrap>
      </div>
      <div ref={refSec3} id="sec-three">
        <HorWrap>
          <OrangeHeader
            active={Sec3InView && transitionStatus === 'entered'}
            direction="left"
          >
            03 The Team
          </OrangeHeader>
          <UsTeam
            active={Sec3InView && transitionStatus === 'entered'}
            data={pageData.people}
          />
          <div ref={refSec35}>
            <TextSection active={Sec35InView} themeStyle="two-columns">
              <PurpleHeader
                active={Sec35InView}
                delay={0.5}
                text={pageData.teamHeader.split(' ')}
              />
              <div>
                <ReactMarkdown children={pageData.teamDescription} />
              </div>
            </TextSection>
          </div>
        </HorWrap>
      </div>
      <div ref={refSec4} id="sec-four">
        <UsPurple
          active={Sec4InView && transitionStatus === 'entered'}
          data={pageData.services}
        />
      </div>
      <div ref={refSec5} id="sec-five">
        <HorWrap>
          <OrangeHeader
            active={Sec5InView && transitionStatus === 'entered'}
            direction="right"
          >
            05 Clients
          </OrangeHeader>
          <TilesBig
            tiles={pageData.clients}
            active={Sec5InView && transitionStatus === 'entered'}
          />
        </HorWrap>
      </div>
      <div ref={refSec6} id="sec-six">
        <HorWrap>
          <OrangeHeader
            active={Sec6InView && transitionStatus === 'entered'}
            direction="left"
          >
            06 Awards
          </OrangeHeader>
          <TilesSmall
            tiles={pageData.awards}
            active={Sec6InView && transitionStatus === 'entered'}
          />
        </HorWrap>
      </div>
      <VideoFull active={transitionStatus === 'entered'} video="783831849" />
      <Newsletter />
      <AsideNav
        active={startPage}
        activeItem={activeSection}
        data={navigation}
        onClickFunc={setActiveSection}
      />
    </Main>
  )
}

export const query = graphql`
  query UsPageQuery {
    datoCmsUsPage {
      seoMetaTags {
        tags
      }
      heroHeader
      heroLine1
      heroLine2
      heroButton {
        text
        textMobile
        internalLink {
          ... on DatoCmsContactPage {
            slug
          }
        }
      }
      wwdHeader
      wwdDescription
      people {
        id
        name
        role
        photo {
          url
        }
      }
      teamHeader
      teamDescription
      services {
        id
        name
      }
      clients {
        id
        logo {
          url
        }
      }
      awards {
        id
        logo {
          url
        }
      }
    }
  }
`

export default UsPage
