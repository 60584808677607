import React from 'react'
import { Wrapper, Content, Person } from './styles.js'

const UsTeam = ({ active, data }) => {
  return (
    <Wrapper active={active}>
      <Content>
        {data.map(person => (
          <Person BgImg={person.photo.url}>
            <h3>{person.name}</h3>
            <p>{person.role}</p>
          </Person>
        ))}
      </Content>
    </Wrapper>
  )
}

export default UsTeam
