import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  margin: 60px 0 100px;
  padding: 0;
`

export const Content = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;
  row-gap: 5px;

  > li {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    display: flex;
    background-color: #f5f5f5;
    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 30%, 0);
          `}
    transition: 0.3s ease;

    svg {
      max-width: 60%;
      height: 20%;
      max-height: 80px;
    }

    &:nth-child(1) {
      transition-delay: 0.5s;
    }

    &:nth-child(2) {
      transition-delay: 0.65s;
    }

    &:nth-child(3) {
      transition-delay: 0.7s;
    }

    &:nth-child(4) {
      transition-delay: 0.95s;
    }

    &:nth-child(5) {
      transition-delay: 1.1s;
    }

    &:nth-child(6) {
      transition-delay: 1.25s;
    }

    &:nth-child(7) {
      transition-delay: 1.4s;
    }

    &:nth-child(8) {
      transition-delay: 1.65s;
    }
  }

  ${({ theme }) => theme.media.m} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.media.l} {
    grid-template-columns: repeat(6, 1fr);
  }
`
