import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  > p,
  > div > p {
    margin: 0 0 20px;
    padding-right: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.66;
    color: black;
    transition: opacity 0.8s 1s ease;
    opacity: ${({ active }) => (active ? '1' : '0')};
  }

  ${({ theme }) => theme.media.l} {
    ${({ themeStyle }) =>
      themeStyle === 'two-columns' &&
      css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > p,
        > div {
          width: 55%;
          padding-right: 15%;
        }
      `}
  }
`
