import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import { Wrapper, Content } from './styles.js'

const TilesBig = ({ active, tiles }) => {
  return (
    <Wrapper active={active}>
      <Content active={active}>
        {tiles.map(tile => (
          <li key={tile.id}>
            <SVG src={tile.logo.url} />
          </li>
        ))}
      </Content>
    </Wrapper>
  )
}

TilesBig.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.object),
}

TilesBig.defaultProps = {
  tiles: [],
}

export default TilesBig
