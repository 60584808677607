import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Parallax, Background } from 'react-parallax'

import { Wrapper, Content, VideoCurtain } from './styles.js'

const VideoFull = ({ active, video }) => {
  const data = useStaticQuery(graphql`
    query VideoSectionQuery {
      datoCmsGeneralInfo {
        videoHeader1
        videoHeader2
        videoHeader3
        videoHeader4
        videoText
      }
    }
  `)

  return (
    <Wrapper active={active}>
      <Parallax strength={200}>
        <Background className="custom-bg">
          <iframe
            src={`https://player.vimeo.com/video/${video}?autoplay=1&loop=1&autopause=0&background=1&transparent=0&muted=1`}
            title="TItle"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </Background>
      </Parallax>
      <Content>
        <h3>
          <span>{data.datoCmsGeneralInfo.videoHeader1}</span>
          <span>{data.datoCmsGeneralInfo.videoHeader2}</span>
          <span>{data.datoCmsGeneralInfo.videoHeader3}</span>
          <span>{data.datoCmsGeneralInfo.videoHeader4}</span>
        </h3>
        <p>{data.datoCmsGeneralInfo.videoText}</p>
      </Content>
      <VideoCurtain active={active}>
        <div></div>
      </VideoCurtain>
    </Wrapper>
  )
}

VideoFull.propTypes = {
  active: PropTypes.bool,
}

VideoFull.defaultProps = {
  active: false,
}

export default VideoFull
